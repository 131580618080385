<template>
  <loading :active="isLoading" :is-full-page="true" :loader="'bars'" />
  <div class="pt-6 mx-4 w-72">
    <div class="flex flex-col justify-center w-full">
      <label for="name">Order ID</label>
      <input
      autocomplete="off"
        name="name"
        type="text"
        class="
          mt-2
          block
          w-full
          px-0.5
          border-0 border-b
          focus:ring-0 focus:border-qgo-secondary-bg
        "
        @input="orderidVerification()"
        v-model="inputs.order_id"
        placeholder="Order ID"
        required
        @blur="blurStatus.order_id = true"
      />
      <p v-if="!errors.validStatus" class="pt-2 text-red-600">
        {{ errors.order_id }}
      </p>
      <button
        @click="createOrder()"
        class="
          w-full
          p-2
          my-4
          text-white
          rounded-md
          bg-qgo-secondary-bg
          focus:outline-none
        "
      >
        Create Order
      </button>
      <button
        @click="$emit('closeCreatePOPup')"
        class="
          w-full
          p-2
          rounded-md
          focus:outline-none
        "
      >
        Close
      </button>
    </div>
  </div>
</template>
<script>
import { defineComponent } from "vue";
import Loading from "vue-loading-overlay";

export default defineComponent({
  setup() {},
  data() {
    return {
      isLoading: false,
      inputs: {
        order_id: "",
      },
      blurStatus: {
        order_id: false,
      },
      errors: {
        order_id: "",
      },
      validStatus: {
        order_id: true,
      },
    };
  },
  methods: {
    orderidVerification() {
      if (this.blurStatus.order_id) {
        if (this.inputs.order_id) {
          this.validStatus.order_id = true;
          this.errors.order_id = "";
        } else {
          this.validStatus.order_id = false;
          this.errors.order_id = "Please enter order id";
        }
      }
    },
    createOrder() {
      this.blurStatus.order_id = true;
      this.orderidVerification();
      if (this.validStatus.order_id) {
        this.isLoading = true;
        this.$http
          .post("qr/create", { order_no: this.inputs.order_id })
          .then((response) => {
              this.isLoading = false;
            if (response && response.data.status) {
              this.resetForm();
                this.$emit('closeCreatePOPup');
              this.$swal.fire({
                icon: "success",
                title: response.data.message,
                timer: 3000,
                toast: true,
                position: "top-end",
                showConfirmButton: false,
              });
            } else {
              this.$swal.fire({
                icon: "error",
                title: response.data.message,
                timer: 3000,
                toast: true,
                position: "top-end",
                showConfirmButton: false,
              });
            }
          })
          .catch((error) => {
            this.isLoading = false;
            this.$swal.fire({
              icon: "error",
              title: error.response.data.message,
              timer: 3000,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
            });
          });
      }
    },
    resetForm() {
      this.inputs.order_id = "";
      this.errors.order_id = "";
      this.validStatus.order_id = true;
      this.blurStatus.order_id = false;
    },
  },
  components: {
    Loading,
  },
});
</script>
