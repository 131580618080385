<template>
  <loading :active="isLoading" :is-full-page="true" :loader="'bars'" />
  <!-- <div class="add-order" @click="addOrder = true">
    <img src="@/assets/img/plus-circle-solid.svg" alt="" />
  </div> -->
  <div class="queue-container relative">
    <div class="flex items-center justify-center py-4 h-28">
      <div>
        <img
          :src="profilePic"
          v-if="profilePic"
          class="w-20 h-20 rounded-full"
        />
        <img v-else alt="logo" :src="logo" class="w-20 h-20 rounded-full" />
      </div>
    </div>
    <div class="flex items-center justify-center h-6">
      <div>
        <p class="text-2xl">{{ vendorName }}</p>
      </div>
    </div>
    <div class="flex items-stretch h-full">
      <div class="flex items-center justify-center w-40 p-2">
        <div>
          <p class="text-sm">Next</p>
          <!-- <p class="m-2 text-2xl font-bold">{{ queueData[1].ticketName }}</p> -->
          <p class="m-2 text-3xl font-bold">
            {{
              currentQRCodeDetails.nextOrder
                ? "Q" + currentQRCodeDetails.nextOrder
                : "-"
            }}
          </p>
        </div>
      </div>
      <div
        class="flex items-center justify-center w-full p-2"
        v-if="availableQR.order_no"
      >
        <!-- <img
          src="../../../assets/img/QR_code.webp"
          class="
            x-sm:w-40 x-sm:h-40
            sm:h-44 sm:w-44
            md:h-60 md:w-60
            2xl:h-72 2xl:w-72
          "
        /> -->
        <qrcode-vue
          :value="currentQRCodeDetails.qrValue"
          :size="size"
          level="L"
          foreground="#009a73"
        />
      </div>
      <div
        v-else
        class="cursor-pointer flex items-center justify-center w-full p-2"
      >
        <span>No QR available</span>
      </div>
      <div class="flex items-center justify-center w-40 p-2">
        <div v-if="availableQR.order_no">
          <button @click="skipQRCode()" class="text-xs focus:outline-none">
            Skip Ticket
            <svg
              class="w-12 h-12 mx-auto my-2"
              xmlns="http://www.w3.org/2000/svg"
              width="32"
              height="26"
              viewBox="0 0 32 26"
            >
              <text
                data-name="􀘨"
                transform="translate(0 1)"
                fill="#009a74"
                font-size="20"
                font-family="SFProText-Regular, SF Pro Text"
              >
                <tspan x="4.291" y="19">􀘨</tspan>
              </text>
            </svg>
          </button>
        </div>
      </div>
    </div>
    <div
      v-if="this.availableQR.order_no"
      class="flex items-center justify-center h-20"
    >
      <div class="">
        <p class="text-l">
          Current Order Number:
          <span class="font-bold">Q{{ this.availableQR.order_no }}</span>
        </p>
      </div>
    </div>
    <div class="flex items-center justify-center h-20">
      <div class="">
        <button
          class="text-gray-600 focus:outline-none"
          id="show-expand-order"
          @click="openExpandOrder"
        >
          <svg
            class="w-5 h-5 mx-auto"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M5 15l7-7 7 7"
            />
          </svg>
          <p class="text-xs">EXPAND ORDERS</p>
        </button>
      </div>
    </div>
    <div class="flex md:h-40 lg:h-40 xl:h-40 2xl:h-48 card-container">
      <!-- <div class="flex h-full px-10 overflow-x-auto overflow-y-hidden"> -->
      <div class="flex h-full px-10">
        <div
          v-for="queue in queueData"
          :key="queue.id"
          class="relative flex pt-1 mx-4 w-36"
        >
          <Card @orderUpdated="getFreeQRCodes()" :queue="queue"></Card>
        </div>
      </div>
    </div>
    <ExpandOrder
      @orderStatusUpdated="getFreeQRCodes()"
      ref="expandOrderModal"
      :data="queueData"
      @closed="
        queueData = [];
        getFreeQRCodes();
      "
    ></ExpandOrder>

    <!-- overlay -->
    <!-- <div class="absolute top-0 left-0 bg-gray-300 w-full h-full overlay-check">
      <div class="flex items-center justify-center py-4 h-28">
        <div>
          <img
            :src="profilePic"
            v-if="profilePic"
            class="w-20 h-20 rounded-full"
          />
          <img v-else alt="logo" :src="logo" class="w-20 h-20 rounded-full" />
        </div>
      </div>
      <div class="flex items-center justify-center h-6">
        <div>
          <p class="text-2xl">{{ vendorName }}</p>
        </div>
      </div>
      <div class="flex flex-col items-center justify-center ">
        <div>
          <p class="text-xl font-bold py-4">
            <img alt="logo" src="@/assets/img/HorizBlackGrn.png" class="w-20 h-20 rounded-full" />
            vendor
          </p>
        </div>
        <div>
          <p class="text-xl font-bold py-4">
            YOUR SUBSCRIPTION IS<br />CURRENTLY PAUSED
          </p>
        </div>
        <div>
          <p class="text-sm py-4" >
            Please unpause your subscription on the Billing screen<br />
            in order to resume using your service.
          </p>
        </div>
        <div>
          <button
                class="
                  flex
                  px-12
                  py-2
                  text-white
                  border-b-2
                  rounded-lg  
                  bg-qgo-primary-bg
                  focus:outline-none
                  text-center
                  justify-center
                  
                "
              >
                Edit Billing
              </button>
        </div>
      </div>
    </div> -->
    <!-- overlay -->
    <div class="overlay-check" v-if="billingStatus != 1">
      <div class="inner-subscription">
        <div class="logo">
          <img :src="logo" alt="" />
          <h2>{{ vendorName }}</h2>
        </div>
        <div class="content-subscription">
          <div class="logo-go">
            <img src="@/assets/img/HorizBlackGrn.png" alt="" />
            <span>Vendor</span>
          </div>
          <h3 v-if="billingStatus == 0">WELCOME TO YOUR ACCOUNT</h3>
          <p v-if="billingStatus == 0">
            To begin using your subscription,<br>Please add a payment method on the Billing screen.
          </p>
          <h3 v-if="billingStatus == 2">YOUR SUBSCRIPTION IS<br>CURRENTLY INACTIVE</h3>
          <p v-if="billingStatus == 2">
            Please add a payment method to begin using<br>your Q&GO Vendors account.
          </p>
          <h3 v-if="billingStatus == 3">YOUR SUBSCRIPTION IS<br />CURRENTLY PAUSED</h3>
          <p v-if="billingStatus == 3">
            Please unpause your subscription on the Billing screen<br />
            in order to resume using your service.
          </p>
          <router-link
          tag="span"
          active-class="active"
          to="/vendor/billing"
          exact
        >
        <a class="billing-btn">Edit Billing</a></router-link>
          
        </div>
      </div>
    </div>
  </div>

  <!-- modal tutorial -->
  <transition name="slide" mode="out-in">
    <modal-view v-if="showtutorial">
      <TutorialModal @skip-tutorial="showtutorial = false"></TutorialModal>
    </modal-view>
  </transition>
  <!-- <transition name="slide" mode="out-in">
    <modal-view v-if="addOrder">
      <CreateOrder
        @closeCreatePOPup="(addOrder = false), getFreeQRCodes()"
      ></CreateOrder>
    </modal-view>
  </transition> -->
  <page-footer>
    <footer-nav></footer-nav>
  </page-footer>
</template>

<script>
import PageFooter from "@/components/pages/Footer.vue";
import FooterNav from "@/views/Vendor/FooterNav.vue";
import Card from "@/views/Vendor/Queue/QueueCards.vue";
import ExpandOrder from "@/views/Vendor/Queue/ExpandOrder.vue";
import ModalView from "@/components/Modal.vue";
import TutorialModal from "@/views/Vendor/Modal/TutorialModal.vue";
import CreateOrder from "@/views/Vendor/Queue/CreateOrder.vue";
import Loading from "vue-loading-overlay";
import QrcodeVue from "qrcode.vue";

export default {
  // emits: ['orderUpdated', 'orderStatusUpdated'],

  data() {
    return {
      billingStatus: 0,
      availableQR: {},
      addOrder: false,
      isLoading: true,
      vendorName: "Sample Vendor",
      showtutorial: false,
      expandOrder: false,
      page: 1,
      queueData: [],
      size: 300,
      currentQRCodeDetails: {
        index: 0,
        nextOrder: 0,
        qrValue: "",
      },
      apiInterval: null,
      frequencyStack: null,
      vendorLogo: "",
    };
  },
  components: {
    PageFooter,
    FooterNav,
    Card,
    ExpandOrder,
    ModalView,
    TutorialModal,
    Loading,
    CreateOrder,
    QrcodeVue,
  },
  methods: {
    openExpandOrder() {
      this.$refs.expandOrderModal.showExpandOrder();
    },
    skipQRCode() {
      if (this.availableQR.order_no) {
        this.isLoading = true;
        this.$http
          .post("qr/skip", { order_no: this.availableQR.order_no })
          .then((response) => {
            this.isLoading = false;
            if (response && response.data && response.data.status) {
              this.getFreeQRCodes();
              this.$swal.fire({
                icon: "success",
                title: response.data.message,
                timer: 3000,
                toast: true,
                position: "top-end",
                showConfirmButton: false,
              });
            } else {
            }
          })
          .catch((error) => {
            this.isLoading = false;
            this.$swal.fire({
              icon: "error",
              title: error.response.data.message,
              timer: 3000,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
            });
          });
      }
    },
    createOrder() {
      this.isLoading = true;
      this.$http
        .post("qr/create", {})
        .then((response) => {
          this.isLoading = false;
          if (response && response.data.status) {
          } else {
            this.$swal.fire({
              icon: "error",
              title: response.data.message,
              timer: 3000,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
            });
          }
        })
        .catch((error) => {
          this.isLoading = false;
          this.$swal.fire({
            icon: "error",
            title: error.response.data.message,
            timer: 3000,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
          });
        });
    },
    getFreeQRCodes() {
      this.$http
        .get("qr/get")
        .then((response) => {
          if (response && response.data.status) {
             this.queueData = [];
            this.availableQR = response.data.data;
            this.currentQRCodeDetails.index = 0;
            this.currentQRCodeDetails.nextOrder = this.availableQR.order_no + 1;
            // let url = `${this.$hostname}?order_id=${this.availableQR.order_no}&vender_Id=${this.availableQR.vendor_id}`;
           
            let url = `${this.$hostname}?order_id=${this.availableQR.order_no}&vender_id=${this.availableQR.vendor_id}&vendor_name=${this.vendorName}&vendor_logo=${this.vendorLogo}`;
            // this.currentQRCodeDetails.qrValue = JSON.stringify({
            //     order_id: this.availableQR.order_no,
            //     vender_Id: this.availableQR.vendor_id,
            //     vendor_name: this.vendorName,
            //   });
            this.currentQRCodeDetails.qrValue = url;
            setTimeout(() => {
            this.queueData = response.data.orders;
              
            }, 0);
            this.manageFrequencyStack();
          } else {
            this.availableQR = {};
            this.queueData = [];
            // this.createOrder();
          }
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
        });
    },

    checkBillingStatus() {
      this.$http
        .get("subscription_items")
        .then((response) => {
          this.isLoading = false;
          if (response && response.data.status) {
            this.billingStatus = response.data.subscription_code;
            //0: no card added, 1: Active, 2: Inactive, 3: Paused
            // if billing status is active then call apis
            if (this.billingStatus == 1) {
              this.getFreeQRCodes();
              this.apiInterval = setInterval(() => {
                this.getFreeQRCodes();
              }, 3000);
            }
          } else {
            this.billingStatus = 0;
          }
        })
        .catch((error) => {
          this.isLoading = false;
        });
    },

    manageFrequencyStack() {
      let readyOrders = this.queueData.filter(
        (q) => q.order_status === "Ready"
      );
      let stackedOrders = sessionStorage.getItem("stackedOrders");
      if (stackedOrders) {
        stackedOrders = JSON.parse(stackedOrders);
        for (let i = 0; i < readyOrders.length; i++) {
          let index = stackedOrders.findIndex(
            (el) => el.order_no === readyOrders[i].order_no
          );
          if (index < 0) {
            readyOrders[i].notificationSent = 0;
            stackedOrders.push(readyOrders[i]);
          }
        }
        sessionStorage.setItem("stackedOrders", JSON.stringify(stackedOrders));
      } else {
        for (let i = 0; i < readyOrders.length; i++) {
          readyOrders[i].notificationSent = 0;
        }
        sessionStorage.setItem("stackedOrders", JSON.stringify(readyOrders));
      }
    },
    getQueueData() {
      this.$http
        .get("order/get")
        .then((response) => {
          if (response.data && response.data.status) {
            this.queueData = response.data.qrdata;
          } else {
            this.queueData = [];
          }
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
          let errormsg = "";
          if (error.response && error.response.data.message) {
            errormsg = error.response.data.message;
          } else {
            errormsg = "Something went wrong";
          }
          this.$swal.fire({
            icon: "error",
            title: errormsg,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 5000,
          });
        });
    },

    APIcallDorSendnotification(order) {
      this.$http
        .post("order/send-notification", { order_no: order.order_no })
        .then((response) => {
          console.log("Notification send");
        })
        .catch((error) => {
          console.log("Error while sending notification", error);
        });
    },

    sendFrequencyNotification() {
      console.log("sendddddd");
      if (sessionStorage.getItem("notificationFrequency")) {
        let notificationFrrequncy = parseInt(
          sessionStorage.getItem("notificationFrequency")
        );
        if (notificationFrrequncy > 1) {
          console.log("notificationFrrequncy", notificationFrrequncy);
          let stackedOrders = sessionStorage.getItem("stackedOrders");
          stackedOrders = JSON.parse(stackedOrders);
          console.log("sending to ", stackedOrders.length);
          // sessionStorage.setItem('stackedOrders', JSON.stringify(stackedOrders));
          for (let i = 0; i < stackedOrders.length; i++) {
            console.log(
              stackedOrders[i].notificationSent,
              notificationFrrequncy
            );
            if (stackedOrders[i].notificationSent < notificationFrrequncy) {
              this.APIcallDorSendnotification(stackedOrders[i]);
              stackedOrders[i].notificationSent++;
            }
          }
          sessionStorage.setItem(
            "stackedOrders",
            JSON.stringify(stackedOrders)
          );
        }
      }
    },
  },
  created() {
    let user = JSON.parse(localStorage.getItem("userData"));
    this.userId = user.id;
    if (user && user.email) {
      this.vendorName = user.name;
      this.logo = user.logo;
      this.vendorLogo = user.logo;
    }
    if (localStorage.getItem("last_login")) {
      this.showtutorial = true;
      localStorage.removeItem("last_login");
    }
    if (!sessionStorage.getItem("notificationFrequency")) {
      this.$http
        .get("console/get-notification")
        .then((response) => {
          if (response && response.data && response.data.status) {
            let reminderData = response.data.reminder.find(
              (e) => e.id == response.data.screen.reminder_prompt
            );
            console.log(reminderData);
            if (reminderData) {
              sessionStorage.setItem(
                "notificationFrequency",
                reminderData.value
              );
            }
            // let repeatFrequ = 0;
            // switch (response.data.screen.reminder_prompt) {
            //   case 1:
            //     repeatFrequ = 2;
            //     break;
            //   case 2:
            //     repeatFrequ = 5;
            //     break;
            //   case 3:
            //     repeatFrequ = 10;
            //     break;
            //   default:
            //     break;
            // }
            // sessionStorage.setItem("notificationFrequency", repeatFrequ);
            this.frequencyStack = setInterval(() => {
              this.sendFrequencyNotification();
            }, 60000);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      this.frequencyStack = setInterval(() => {
        this.sendFrequencyNotification();
      }, 60000);
    }

    if (window.screen.width < 1024) {
      this.size = 100;
    }
    this.checkBillingStatus();
  },
  computed: {
    profilePic() {
      return this.$store.getters["profile/vendorLogo"];
    },
  },
  unmounted() {
    if (this.apiInterval) {
      clearInterval(this.apiInterval);
    }
    if (this.frequencyStack) {
      clearInterval(this.frequencyStack);
    }
  },
};
</script>
<style lang="scss" scoped>
.add-order {
  position: fixed;
  right: 40px;
  top: 40px;
  width: 30px;
  cursor: pointer;
}
.overlay-check {
  background: rgba(238, 238, 238, 0.9);
}
.logo-go img {
  width: 100px;
  object-fit: contain;
  margin-right: 8px;
}
.overlay-check {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
}
.overlay-check .logo img {
  width: 5rem;
  height: 5rem;
  margin: 0 auto;
  border-radius: 50%;
}
.overlay-check .logo {
  margin-top: 15px;
}
.overlay-check .logo h2 {
  margin-top: 10px;
  font-size: 24px;
}
.content-subscription {
  margin-top: 200px;
}
.logo-go {
  display: flex;
  align-items: center;
  justify-content: center;
}
.content-subscription h3 {
  font-size: 23px;
  font-weight: 700;
  line-height: 1.4;
  margin: 12px 0;
  color: #000;
}
.content-subscription p {
  color: #000;
}
.content-subscription a.billing-btn {
  margin-top: 60px;
  display: inline-block;
  background-color: #009a73;
  min-width: 350px;
  min-height: 50px;
  line-height: 50px;
  color: #fff;
  font-weight: 700;
  border-radius: 8px;
  font-size: 20px;
}
.logo-go span {
  color: #009a73;
  border-left: 1px solid #009a73;
  padding-left: 12px;
  font-size: 26px;
  font-weight: 400;
}
@media screen and (max-width: 767px) {
  .content-subscription {
    margin-top: 40px;
  }
  .content-subscription h3 {
    font-size: 18px;
  }
  .content-subscription p {
    max-width: 270px;
    margin: 0 auto;
    line-height: 1.5;
    font-size: 14px;
  }
  .content-subscription a.billing-btn {
    margin-top: 40px;
    min-width: 240px;
    min-height: 40px;
    line-height: 40px;
    font-size: 17px;
  }
}
</style>
